<template>
  <div
    class="block-navigation-language-dropdown"
    :class="{ 'is-open': isOpen }"
    @click="toggleOpen(false)"
    @keypress="toggleOpen(false)"
  >
    <div class="block-navigation-language-dropdown__current">
      {{ locales.find((lang) => lang.code === locale)?.name }}
    </div>

    <ul
      ref="optionsRef"
      class="block-navigation-language-dropdown__list"
    >
      <li
        v-for="lang in inactiveLocales"
        :key="lang.code"
        class="block-navigation-language-dropdown__list-item"
        :class="{ 'is-active': locale === lang.code }"
      >
        <NuxtLink
          v-if="!isStorybook"
          class="block-navigation-language__list-item-link"
          :to="processLocalePath(lang.code)"
        >
          {{ lang.name }}
        </NuxtLink>

        <div
          v-else
          class="block-navigation-language__list-item-link"
          @click="changeLocale(lang.code)"
          @keypress="changeLocale(lang.code)"
        >
          {{ lang.name }}
        </div>
      </li>
    </ul>

    <IonIcon
      icon-name="chevron-down"
      class="block-navigation-language-dropdown__icon"
      :icon-color="textColor"
    />
  </div>
</template>

<script setup>
const props = defineProps({
    backgroundColor: {
        type: String,
        default: 'var(--c-primary)',
    },

    textColor: {
        type: String,
        default: 'var(--c-white)',
    },

    borderRadius: {
        type: String,
        default: 'var(--b-radius--small)',
    },
});

/*
    Styles
*/
const {
    backgroundColor,
    textColor,
    borderRadius,
} = toRefs(props);

/*
    Internationalization
*/
const switchLocalePath = useSwitchLocalePath();
const { locale, locales } = useI18n();
const currentLocale = ref(locale.value);

const inactiveLocales = computed(
    () => locales.value?.filter((lang) => lang.code !== currentLocale.value) || [],
);

const runtimeConfig = useRuntimeConfig();
const isStorybook = runtimeConfig.public.IS_STORYBOOK;

/*
  Handle home link
*/
const processLocalePath = (langCode) => {
    let path = switchLocalePath(langCode);

    // Remove query params
    path = path.replace(/\?.*$/, '');

    if (path.endsWith('/home')) {
        path = path.replace(/home$/, '');
    }

    return path;
};

watch(() => locale.value, (newValue) => {
    window.setTimeout(() => {
        currentLocale.value = newValue;
    }, 0);
});

/* Storybook Only */
const changeLocale = (newLocale) => {
    locale.value = newLocale;
};

/*
    Dropdown
*/
const closedHeight = '0px';
const optionsHeight = ref(closedHeight);
const optionsRef = ref(null);

const isOpen = ref(false);
const toggleOpen = (forceClose) => {
    isOpen.value = forceClose ? false : !isOpen.value;
    optionsHeight.value = isOpen.value ? `${optionsRef.value.scrollHeight}px` : closedHeight;
};

/* Watch window width to set height of box accordingly on resize */
const { width } = useWindowSize();
watch(() => width.value, () => {
    if (!props.isFullScreen) {
        optionsHeight.value = isOpen.value ? `${optionsRef.value.scrollHeight}px` : closedHeight;
    }
});
</script>

<style lang="scss" scoped>

.block-navigation-language-dropdown {
    @include fluid('--padding-top', 12px, 15px, 18px);
    @include fluid('padding-bottom', 12px, 15px, 18px);
    @include fluid('padding-left', 15px, 20px, 20px);
    @include fluid('padding-right', 34px, 53px, 53px);
    @include fluid('--font-size', 13px, 12px, 15px);
    @include fluid('min-width', 74px, 90px, 93px);

    position: relative;
    display: inline-flex;
    flex-direction: column;
    padding-top: var(--padding-top);
    border-radius: v-bind(borderRadius);
    background-color: v-bind(backgroundColor);
    color: v-bind(textColor);
    cursor: pointer;
    font-family: var(--f-family--primary);
    font-size: var(--font-size);
    font-weight: var(--f-weight--700);
    line-height: normal;
    text-transform: uppercase;

}

.block-navigation-language-dropdown__current {
    min-width: 24px;
}

.block-navigation-language-dropdown__list {
    @include fluid('padding-left', 15px, 20px, 20px);
    @include fluid('padding-right', 43px, 53px, 53px);

    position: absolute;
    top: calc(var(--padding-top) + var(--font-size));
    left: 0;
    display: flex;
    overflow: hidden;
    width: 100%;
    max-height: v-bind(optionsHeight);
    flex-direction: column;
    border-radius: v-bind(borderRadius);
    background: v-bind(backgroundColor);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    row-gap: 10px;
    transition: max-height 0.3s var(--ease-in-out--quart) 0.15s, opacity 0.3s var(--ease-in-out--sine);

    .is-open & {
        opacity: 1;
        transition: max-height 0.3s var(--ease-in-out--quart), opacity 0.3s var(--ease-in-out--sine) 0.15s;
    }
}

.block-navigation-language-dropdown__list-item {
    opacity: 0;
    transition: opacity 0.3s var(--ease-in-out--sine);

    .is-open & {
        opacity: 1;
        transition: opacity 0.3s var(--ease-in-out--sine) 0.15s;
    }

    &:last-child {
        @include fluid('margin-bottom', 12px, 15px, 18px);
    }

    &:first-child {
        @include fluid('margin-top', 12px, 12px, 14px);
    }
}

.block-navigation-language-dropdown__icon {
    @include fluid('right', 15px, 20px, 20px);
    @include fluid('top', 16px, 19px, 22px);

    position: absolute;
    transition: transform 0.3s var(--ease-in-out--quart);

    .is-open & {
        transform: rotate(180deg) translateY(1px);
    }

    ::v-deep(.ion-icon__svg) {
        @include fluid('height', 10px, 10px, 12px);
    }
}
</style>
